import React from 'react';

const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const UserList = React.lazy(() => import('./pages/Users/UserList'));
const UserEdit = React.lazy(() => import('./pages/Users/UserEdit'));
const LogList = React.lazy(() => import('./pages/Logs/LogList'));
const InstanceList = React.lazy(() => import('./pages/Instances/InstanceList'));
const InstanceDetails = React.lazy(() =>
  import('./pages/Instances/InstanceDetails'),
);
const TerminalList = React.lazy(() => import('./pages/Terminals/TerminalList'));
const TerminalCreate = React.lazy(() =>
  import('./pages/Terminals/TerminalCreate'),
);

const InstanceCreate = React.lazy(() =>
  import('./pages/Instances/InstanceCreate'),
);
const InstanceEdit = React.lazy(() => import('./pages/Instances/InstanceEdit'));

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/instances', name: 'Instanzen', element: InstanceList },
  {
    path: '/instances/new',
    name: 'Instanz erstellen',
    element: InstanceCreate,
  },
  {
    path: '/instances/:id/edit',
    name: 'Instanz bearbeiten',
    element: InstanceEdit,
  },
  {
    path: '/instances/:id',
    name: 'Instanz Details',
    element: InstanceDetails,
  },
  {
    path: '/terminals/new',
    name: 'Terminal hinzufügen',
    element: TerminalCreate,
  },
  { path: '/terminals', name: 'Terminals', element: TerminalList },
  { path: '/users', name: 'Benutzer', element: UserList },
  { path: '/users/new', name: 'Benutzer hinzufügen', element: UserEdit },
  { path: '/users/:id', name: 'Benutzer bearbeiten', element: UserEdit },
  { path: '/logs', name: 'Logs', element: LogList },
];

export default routes;
