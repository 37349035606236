import React from 'react';
import CIcon from '@coreui/icons-react';
import {
  cilSpeedometer,
  cilStorage,
  cilGroup,
  cilListRich,
  cilBraille,
} from '@coreui/icons';
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react';

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Instanzen',
    to: '/instances',
    icon: <CIcon icon={cilStorage} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Terminals',
    to: '/terminals',
    icon: <CIcon icon={cilBraille} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'System',
  },
  {
    component: CNavItem,
    name: 'Benutzer',
    to: '/users',
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Logs',
    to: '/logs',
    icon: <CIcon icon={cilListRich} customClassName="nav-icon" />,
  },
];

export default _nav;
