import React from 'react';
import { CFooter } from '@coreui/react';

const AppFooter = () => {
  return (
    <CFooter>
      <div>
        Cloud Management
        <span className="ms-1">&copy; 2022</span>
      </div>

      <div className="ms-auto">
        <span className="me-1">Made with &hearts; by</span>
        <span className="me-1">
          <a
            href="https://eichhorn.services"
            target="_blank"
            rel="noopener noreferrer">
            eichhorn media + it services
          </a>
        </span>
        <span>for ZESI GmbH</span>
      </div>
    </CFooter>
  );
};

export default React.memo(AppFooter);
